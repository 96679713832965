<template>
    <modal ref="cancelarPost" titulo="Cancelar postulación" icon="close">
        <div class="row mx-0 justify-center text-center">
            <div class="col-10">
                ¿Desea cancelar la postulación?
            </div>
        </div>
        <div class="row mx-0 justify-center mb-2 text-center">
            <div class="col-10 f-15">
                Tenga en cuenta que sus datos serán eliminados, no podrá ser evaluado o el proceso se interrumpirá.
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    methods: {
        toggle(){
            this.$refs.cancelarPost.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
